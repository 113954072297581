// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
	production: false,
	isMockEnabled: false, // You have to switch this, when your real back-end is done
	Authorization: 'Base64 YWRtaW46YWRtaW5AMzIx',
	api: {
		// apiURL: window.location.protocol + "//preskil.fortiddns.com:8021/api/v2"
		// apiURL: window.location.protocol + "//sun.smartpoint.in/api/v3"
		//Live
		//apiURL: window.location.protocol + "//screenagedev.smartpoint.in/api/v3",
		//LIVE tEST api
		apiURL: window.location.protocol + "//screenagedevapi.smartpoint.in/api/v3",
		//apiURL: window.location.protocol + "//wyntronix.smartpoint.in/api/v2"
		//http://localhost:5962/

		//local
		//apiURL: window.location.protocol + "//localhost:5962/api/v3",
		//apiURLForToken: window.location.protocol + "//localhost:5962/api/Token",
		// Testing

		//live
		// apiURLForToken: window.location.protocol + "//screenagedev.smartpoint.in/api/Token",
		// apiURLForImage: window.location.protocol + "//screenagedev.smartpoint.in"
		//LIVE tEST api
		apiURLForToken: window.location.protocol + "//screenagedevapi.smartpoint.in/api/Token",
		apiURLForImage: window.location.protocol + "//screenagedevapi.smartpoint.in"


		//Client server
		// apiURL: window.location.protocol + "//www.cmsmercuryguestapp.com:8021/api/v3",
		// apiURLForToken: window.location.protocol + "//www.cmsmercuryguestapp.com:8021/api/Token",
		// apiURLForImage: window.location.protocol + "//www.cmsmercuryguestapp.com:8021"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
