import { Component, OnInit, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../core/services/auth.service';
import { CommonService } from 'src/app/core/services/common.service';
import { AppConfig } from "config";
import { NgxNavigationWithDataComponent } from 'ngx-navigation-with-data';
import { Subscription, timer } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
  showFiller: boolean
  projectPath;
  imageBasePath;
  projectName;
  notificationLength: any = [];
  newBookingNotificationLen: any = [];
  clearNotificationData = {
    ClearNotificationByUserList: [
    ]
  }
  notificationItems: Array<{}>;
  bookingNotificationItems: Array<{}>;
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };
  UserProfileImage;
  UserName;
  openMobileMenu: boolean;
  HotelId
  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();
  Baseurl
  chatStatus
  timerSubscription: Subscription;
  myBgColor = '#1A1A1A'
  color1: string;
  
 
  
  constructor(private router: Router, private authService: AuthenticationService,
    private service: CommonService, public navCtrl: NgxNavigationWithDataComponent) { }

  ngOnInit() {
    // setTimeout(()=>{
    //   this.color1 = '#411497';
    // },1000);
    this.HotelId = localStorage.getItem("HotelId")
    this.Baseurl = AppConfig.project_url
    // get the notifications
    this._fetchNotifications();
    // get booking notification
    this._fetchNewBookingNotification();
    // get the language
    this._fetchLanguages();

    this.service.httpService({ 'UserId': localStorage.getItem("UserId") }, "getAdminUserById").subscribe(response => {
      localStorage.setItem("UserName", response.ResponseData.FullName);
      localStorage.setItem("UserImage", response.ResponseData.UserImage);
      this.UserName = localStorage.getItem("UserName");
      this.UserProfileImage = localStorage.getItem("UserImage") == "null" ? "assets/images/users/avatar.png" : localStorage.getItem("UserImage");
    });

//logo image

this.service.httpService({ "HotelId": this.HotelId }, 'getSettingView').subscribe(response => {
  console.log(response.ResponseData);
  this.color1=response.ResponseData.WebAppColorCode
      if(response.ResponseData.WebLogoPath != ''){
       
    this.imageBasePath = response.ResponseData.WebLogoPath != '' && response.ResponseData.WebLogoPath != null ? AppConfig.project_url + response.ResponseData.WebLogoPath : '';
    console.log(this.imageBasePath);   
  }
      else
      {
        this.imageBasePath = AppConfig.image_base_path;
      }
  

}, error => console.log(error));
//end

    this.selectedLanguage = this.languages[0];
    this.openMobileMenu = false;
    this.imageBasePath = AppConfig.image_base_path;
    this.projectName = AppConfig.app_name;
    this.checkChatMsg()
  }

  getRoomserviceTiming() {
    this.service.httpService({ "HotelId": this.HotelId }, 'getSettingView').subscribe(response => {

      console.log(response);
      this.color1 = response.ResponseData

    }, error => console.log(error));
  }
  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();

    this.mobileMenuButtonClicked.emit();
    this.showFiller = true
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }
  checkChatMsg() {
    // this.timerSubscription = timer(0, 5000).pipe(
      map(() => {
        this.service.httpService({}, 'checkChatmsg').subscribe((response: any) => {
          if (response.Status) {
            if (response.ResponseData == 'Yes') {
              this.chatStatus = true
            }else{
              this.chatStatus = false
            }
          }
        })
      })
    // ).subscribe();
  }

  clearNotification() {

    if (this.HotelId == 0) {
      this.HotelId = ""
    }
    else {
      this.HotelId = localStorage.getItem("HotelId")
    }
    var postData = {
      "UserId": localStorage.getItem('UserId'),

      "HotelId": this.HotelId
    };
    this.service.httpService(postData, 'removeNotificationlist').subscribe(response => {
      if (response.IsException == null) {
        this.notificationItems = response.ResponseData;
      }

    });


  }
  clearNotificationByUser() {
    this.service.httpService(this.clearNotificationData, 'clearNotificationByUser').subscribe(response => {
      if (response.Status) {
        var postData = {
          "AdminId": localStorage.getItem('UserId'),
          "HotelId": this.HotelId
        };
        this.service.httpService(postData, 'getNotificationList').subscribe(response => {
          if (response.IsException == null) {
            this.notificationItems = response.ResponseData;
          }
        })
      }
    });
    this._fetchNewBookingNotification();
    this.router.navigate(['./hotels/notification-list'])
  }

  /**
   * Fetches the supported languages
   */
  _fetchLanguages() {
    this.languages = [{
      id: 1,
      name: 'English',
      flag: 'assets/images/flags/us.jpg',
    },
    {
      id: 2,
      name: 'German',
      flag: 'assets/images/flags/germany.jpg',
    },
    {
      id: 3,
      name: 'Italian',
      flag: 'assets/images/flags/italy.jpg',
    },
    {
      id: 4,
      name: 'Spanish',
      flag: 'assets/images/flags/spain.jpg',
    },
    {
      id: 5,
      name: 'Russian',
      flag: 'assets/images/flags/russia.jpg',
    }];

    this.selectedLanguage = this.languages[0];
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {

    if (this.HotelId == 0) {
      this.HotelId = ""
    }
    else {
      this.HotelId = localStorage.getItem("HotelId")
    }
    var postData = {
      "AdminId": localStorage.getItem('UserId'),

      "HotelId": this.HotelId
    };
    this.service.httpService(postData, 'getNotificationList').subscribe(response => {
      if (response.IsException == null) {
        this.notificationItems = response.ResponseData;
        if (response.ResponseData != null) {
          this.notificationLength = response.ResponseData.length
          for (let i = 0; i < response.ResponseData.length; i++) {
            this.clearNotificationData.ClearNotificationByUserList.push({
              "NotificationId": response.ResponseData[i].NotificationId,
              "AdminId": localStorage.getItem('UserId')
            })
          }
        }
        console.log(this.clearNotificationData);

      }

    });


  }
  _fetchNewBookingNotification() {
    if (this.HotelId == 0) {
      this.HotelId = ""
    }
    else {
      this.HotelId = localStorage.getItem("HotelId")
    }
    var postData = {
      "AdminId": localStorage.getItem('UserId'),
      "HotelId": this.HotelId
    };
    this.service.httpService(postData, 'getNewBookingNotificationList').subscribe(response => {
      if (response.Status) {
        this.bookingNotificationItems = response.ResponseData
        this.newBookingNotificationLen = response.ResponseData.length
      }
    })
  }
  manageLanguage() {
    window.open("http://65.1.73.181:3001")
    //window.open("http://65.1.73.181:5003")
  }
  viewAllnotification() {
    this.router.navigate(['./hotels/notification-list'])
  }
}
